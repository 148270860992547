.pokemon-card-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.pokemon-card-container:hover {
  transform: scale(1.05);
}
.pokemon-card-container-image {
  max-height: 250px;
  margin-left: auto;
  margin-right: auto;
}
