.card-container {
  display: flex;
  flex-direction: column;
  border: 3px ;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  /*max-width: 200px;*/
  text-align: left;
  background-color: #ffee73;
  text-decoration: none;
  color: black;
}

.rainbow:hover {
  -webkit-animation:rainbow 1s infinite;
  -ms-animation:rainbow 1s infinite;
  -o-animation:rainbow 1s infinite;
  animation:rainbow 1s infinite;
}

@-webkit-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
}
@-ms-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
}
@-o-keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
}
@keyframes rainbow {
  0% {color: #ff0000;}
  10% {color: #ff8000;}
  20% {color: #ffff00;}
  30% {color: #80ff00;}
  40% {color: #00ff00;}
  50% {color: #00ff80;}
  60% {color: #00ffff;}
  70% {color: #0080ff;}
  80% {color: #0000ff;}
  90% {color: #8000ff;}
  100% {color: #ff0080;}
}

.card-container:hover {
  transform: scale(1.05);
}
.card-container-image {
  max-width: 200px;
  max-height: 70px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}
