.app-title {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 76px;
  color: #2a75bb;
  font-family: 'pokemon';
  text-align: center;
}

html {
  height:100%;
  overflow-y: scroll;
}

body {
  margin:0;
}

.bg {
  animation:slide 6s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.content {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:fixed;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}

h1 {
  font-family:monospace;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

@font-face {
  font-family: 'gameboy';
  src: local('gameboy'), url(./fonts/early-gameboy.ttf) format('truetype');
}

@font-face {
  font-family: 'pokemon';
  src: local('pokemon'), url(./fonts/pokemon-hollow.ttf) format('truetype');

}

.app-description {
  font-family: gameboy;
  text-align: justify;
}

.app-header {
  background: #ffee73;
  margin-left: 25%;
  margin-right: 25%;
  border-radius: 5px;
  border-style: solid;
  margin-top: 50px;
  width: 50%;
  border: 3px ;
  border-style: solid;
  border-color: black;
  padding: 20px;
}
